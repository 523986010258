export const Colors = {
    black: '#000',
    brown: '#643200',
    gold: '#e2cb29',
    greenLeaf: '#00b800',
    red: '#ff0000',
    white: '#ffffff',
    // unused colors
    //beige: '#ffb68a',
};
